body {
  margin: 0;
  padding: 0;
  font-family: iransans, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('/content/images/web-bg.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.dev-body{
  background-image: none!important;
  background-color : #91ad92!important;
}
/* .MuiPaper-root-237 {
  background-color: #ffffff7d;
} */

/* * {
  font-family: iransans;
} */

code, button {
  font-family: iransans, source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.fr-view {
  /* direction: rtl; */
  padding: 20px;
}

textarea {
  min-height: 60px;
  overflow:hidden;
}
.oneline-input textarea {
  min-height: auto;
  overflow:hidden;
}
.clearfix::after{clear:both;display:block;content:"";height:0}.hide-by-clipping{position:absolute;width:1px;height:1px;padding:0;margin:-1px;overflow:hidden;clip:rect(0,0,0,0);border:0}.fr-modal .fr-modal-wrapper .fr-modal-body .fr-font-awesome-modal{text-align:left;padding:20px 20px 10px}.fr-modal .fr-modal-wrapper .fr-modal-body .fr-font-awesome-modal .fr-font-awesome-list{margin-bottom:20px}.fr-modal .fr-modal-wrapper .fr-modal-body .fr-font-awesome-modal .fr-font-awesome-title{font-size:20px;padding:6px 0 4px;margin:15px 0 5px;border-bottom:solid 1px #f0f0f0}.fr-modal .fr-modal-wrapper .fr-modal-body .fr-font-awesome-modal .fr-font-awesome{display:inline-block;font-size:16px;width:20px;height:20px;padding:16px;line-height:20px;cursor:default;font-weight:400;-webkit-box-sizing:content-box;-moz-box-sizing:content-box;box-sizing:content-box;text-align:center;margin:-1px 0 0 -1px}


.filepond--panel-root, .filepond--panel-center {
  background-color: rgba(255, 255, 255, 0.3);
  border: 0px solid #2c3340;
}

/* bordered drop area */
.no-browse .filepond--panel-root {
  display:none;
}

.no-browse.filepond--root .filepond--drop-label {
  display:none;
}

.filepond--panel-top::after, .filepond--panel-bottom::before {
  background-color: transparent;
}
.filepond--drop-label label {
  text-shadow: 1px 1px #ffffff73;
  color : #00000087;
}

.filepond--item-panel, .filepond--item-panel
{
  background-color: #64605e;
}

.filepond--root {
  margin-bottom:0
}



.editable-lable{
  min-height: 1px;
}