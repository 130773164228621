.App {
  text-align: center;
}

.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

a, a:hover{
  text-decoration: none;
}


.recharts-tooltip-wrapper{
  /* direction: rtl; */
} 
.recharts-text {

}
/**
*
*	Name:			IRAN Sans-Serif Font
*	Version:		5.0
*	Author:			Moslem Ebrahimi (moslemebrahimi.com)
*	Created on:		Dec 25, 2012
*	Updated on:		Sep 01, 2017
*	Website:		             http://fontiran.com
*	Copyright:		Commercial/Proprietary Software
--------------------------------------------------------------------------------------
فونت های ایران سن سریف یک نرم افزار مالکیتی محسوب می شود. جهت آگاهی از قوانین استفاده از این فونت ها لطفا به وب سایت (فونت ایران دات کام) مراجعه نمایید
--------------------------------------------------------------------------------------
IRAN Sans-serif fonts are considered a proprietary software. To gain information about the laws regarding the use of these fonts, please visit www.fontiran.com 
--------------------------------------------------------------------------------------
This set of fonts are used in this project under the license: (.....)
--------------------------------------------------------------------------------------
*	
**/
@font-face {
	font-family: IRANSans;
	font-style: normal;
	font-weight: 900;
	src: url(/static/media/IRANSansWeb_Black.465387ee.eot);
	src: url(/static/media/IRANSansWeb_Black.465387ee.eot?#iefix) format('embedded-opentype'),  
		 url(/static/media/IRANSansWeb_Black.63911f38.woff2) format('woff2'),  
		 url(/static/media/IRANSansWeb_Black.818f7796.woff) format('woff'),  
		 url(/static/media/IRANSansWeb_Black.71744393.ttf) format('truetype');
}
@font-face {
	font-family: IRANSans;
	font-style: normal;
	font-weight: bold;
	src: url(/static/media/IRANSansWeb_Bold.a65cbf91.eot);
	src: url(/static/media/IRANSansWeb_Bold.a65cbf91.eot?#iefix) format('embedded-opentype'),  
		 url(/static/media/IRANSansWeb_Bold.2f6f164c.woff2) format('woff2'),  
		 url(/static/media/IRANSansWeb_Bold.d44408d0.woff) format('woff'),  
		 url(/static/media/IRANSansWeb_Bold.9471804b.ttf) format('truetype');
}
@font-face {
	font-family: IRANSans;
	font-style: normal;
	font-weight: 500;
	src: url(/static/media/IRANSansWeb_Medium.c18ca268.eot);
	src: url(/static/media/IRANSansWeb_Medium.c18ca268.eot?#iefix) format('embedded-opentype'),  
		 url(/static/media/IRANSansWeb_Medium.7c1c84da.woff2) format('woff2'),  
		 url(/static/media/IRANSansWeb_Medium.7bfa046d.woff) format('woff'),  
		 url(/static/media/IRANSansWeb_Medium.89489585.ttf) format('truetype');
}
@font-face {
	font-family: IRANSans;
	font-style: normal;
	font-weight: 300;
	src: url(/static/media/IRANSansWeb_Light.9d7efb91.eot);
	src: url(/static/media/IRANSansWeb_Light.9d7efb91.eot?#iefix) format('embedded-opentype'),  
		 url(/static/media/IRANSansWeb_Light.7c207019.woff2) format('woff2'),  
		 url(/static/media/IRANSansWeb_Light.aac7a640.woff) format('woff'),  
		 url(/static/media/IRANSansWeb_Light.e5b2acae.ttf) format('truetype');
}
@font-face {
	font-family: IRANSans;
	font-style: normal;
	font-weight: 200;
	src: url(/static/media/IRANSansWeb_UltraLight.eb6bb794.eot);
	src: url(/static/media/IRANSansWeb_UltraLight.eb6bb794.eot?#iefix) format('embedded-opentype'),  
		 url(/static/media/IRANSansWeb_UltraLight.e0e6c92f.woff2) format('woff2'),  
		 url(/static/media/IRANSansWeb_UltraLight.2097362b.woff) format('woff'),  
		 url(/static/media/IRANSansWeb_UltraLight.f8a4f691.ttf) format('truetype');
}
@font-face {
	font-family: IRANSans;
	font-style: normal;
	font-weight: normal;
	src: url(/static/media/IRANSansWeb.70a5f97c.eot);
	src: url(/static/media/IRANSansWeb.70a5f97c.eot?#iefix) format('embedded-opentype'),  
		 url(/static/media/IRANSansWeb.9d38733b.woff2) format('woff2'),  
		 url(/static/media/IRANSansWeb.50f4bba2.woff) format('woff'),  
		 url(/static/media/IRANSansWeb.244a401e.ttf) format('truetype');
}

body {
  margin: 0;
  padding: 0;
  font-family: iransans, -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-image: url('/content/images/web-bg.jpg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
}
.dev-body{
  background-image: none!important;
  background-color : #91ad92!important;
}
/* .MuiPaper-root-237 {
  background-color: #ffffff7d;
} */

/* * {
  font-family: iransans;
} */

code, button {
  font-family: iransans, source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.fr-view {
  /* direction: rtl; */
  padding: 20px;
}

textarea {
  min-height: 60px;
  overflow:hidden;
}
.oneline-input textarea {
  min-height: auto;
  overflow:hidden;
}
.clearfix::after{clear:both;display:block;content:"";height:0}.hide-by-clipping{position:absolute;width:1px;height:1px;padding:0;margin:-1px;overflow:hidden;clip:rect(0,0,0,0);border:0}.fr-modal .fr-modal-wrapper .fr-modal-body .fr-font-awesome-modal{text-align:left;padding:20px 20px 10px}.fr-modal .fr-modal-wrapper .fr-modal-body .fr-font-awesome-modal .fr-font-awesome-list{margin-bottom:20px}.fr-modal .fr-modal-wrapper .fr-modal-body .fr-font-awesome-modal .fr-font-awesome-title{font-size:20px;padding:6px 0 4px;margin:15px 0 5px;border-bottom:solid 1px #f0f0f0}.fr-modal .fr-modal-wrapper .fr-modal-body .fr-font-awesome-modal .fr-font-awesome{display:inline-block;font-size:16px;width:20px;height:20px;padding:16px;line-height:20px;cursor:default;font-weight:400;box-sizing:content-box;text-align:center;margin:-1px 0 0 -1px}


.filepond--panel-root, .filepond--panel-center {
  background-color: rgba(255, 255, 255, 0.3);
  border: 0px solid #2c3340;
}

/* bordered drop area */
.no-browse .filepond--panel-root {
  display:none;
}

.no-browse.filepond--root .filepond--drop-label {
  display:none;
}

.filepond--panel-top::after, .filepond--panel-bottom::before {
  background-color: transparent;
}
.filepond--drop-label label {
  text-shadow: 1px 1px #ffffff73;
  color : #00000087;
}

.filepond--item-panel, .filepond--item-panel
{
  background-color: #64605e;
}

.filepond--root {
  margin-bottom:0
}



.editable-lable{
  min-height: 1px;
}
